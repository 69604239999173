import Ready from '@/utils/ready'
import axios from 'axios'
import { debounce } from 'lodash'

const selector = '[data-ref="headerSearch"]';

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        const toggler: any = element.querySelector('[data-ref="headerSearchToggler"]')
        const closer: any = element.querySelector('[data-ref="headerSearchCloser"]')
        const input: any = element.querySelector('[data-ref="headerSearchInput"]')
        const panelTarget: any = element.querySelector('[data-search-panel-target]')
        const api: string = input.dataset.api || ''
        const cancelToken = axios.CancelToken
        let source: any = cancelToken.source()
        let requestPending: any = false
        const mobileBreakpoint = 1200

        toggler.addEventListener('click', () => {
            const isMobile: boolean = window.innerWidth < mobileBreakpoint
            if (!isMobile) {
                input.classList.add('open')
                closer.classList.add('visible')
                input.focus()
            }
        })

        closer.addEventListener('click', () => {
            changeValue('')
            const isMobile: boolean = window.innerWidth < mobileBreakpoint
            if (!isMobile) {
                input.classList.remove('open')
                closer.classList.remove('visible')
            }
        })

        document.addEventListener('click', (e: any) => {
            const isMobile: boolean = window.innerWidth < mobileBreakpoint
            if (!isMobile) {
                if (!e.target.closest(selector)) {
                    input.classList.remove('open')
                    closer.classList.remove('visible')
                    changeValue('')
                }
            }
        })

        const search = () => {
            if (input.value) {
                if (requestPending) {
                    source.cancel()
                    source = cancelToken.source()
                }
                requestPending = true
                axios.get(api, {
                    cancelToken: source.token,
                    params: {
                        research: input.value,
                    },
                }).then((res: any) => {
                    panelTarget.innerHTML = res.data
                    watchSuggestion()
                }).finally(() => {
                    requestPending = false
                })
            } else {
                panelTarget.innerHTML = ''
            }
        }

        input.addEventListener('input', debounce(search, 300))

        window.addEventListener('keydown', (e: any) => {
            if (e.key === 'Escape' && input === document.activeElement) {
                // onFocusOut();
                changeValue('')
            }
        })

        const watchSuggestion = () => {
            const panel: any = element.querySelector('[data-ref="headerSearchPanel"]')

            Array.from(panel.querySelectorAll('[data-suggestion]')).forEach((suggestion: any) => {
                suggestion.addEventListener('click', fillInputWithEventValue)
            })
        }

        const fillInputWithEventValue = (e: any) => {
            const value = e.target.dataset.value

            if (value !== input.value) {
                changeValue(value)
            }
        }

        const changeValue = (value: string) => {
            const event = new Event('input')
            input.value = value
            input.dispatchEvent(event)
        }
    })
})()

